import Vue from 'vue'
import router from '@/router'
import i18n from '@/plugins/i18n'
import api from '@/api/api'
import { getServiceRootComponent, showConsoleError } from '@/common/utils'
import { ActionContext, Commit, Dispatch } from 'vuex'
import { ServiceDescription } from '@/types/services.types'
import {
  SERVICE_NAMES,
  ServicePermissionDescription,
  SERVICE_INITIALIZATION_STATUSES,
  ServiceComponent,
} from '@docshouse/dh-ui-types'
import ServiceWrapperView from '@/views/ServiceWrapperView.vue'

interface ServicesState {
  services: Record<string, ServiceDescription> | Record<string, never>
}

const getDefaultState = () => {
  return {
    services: {},
  } as ServicesState
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    services(state: ServicesState) {
      return state.services
    },
    servicesArray(state: ServicesState) {
      return Object.values(state.services)
    },
    servicesInitializedArray(state: ServicesState, getters: any) {
      return getters.servicesArray.filter(
        (service: ServiceDescription) => service.initializationStatus === SERVICE_INITIALIZATION_STATUSES.SUCCESS
      )
    },
    serviceInitializationStatus: (state: ServicesState) => (serviceName: ServiceDescription['name']) => {
      return state.services[serviceName]?.initializationStatus
    },
    serviceInitializationDetails: (state: ServicesState) => (serviceName: ServiceDescription['name']) => {
      return state.services[serviceName]?.initializationDetails
    },
    activeService(state: ServicesState, getters: any) {
      return getters.servicesArray?.find((service: ServiceDescription) => service.isActive)
    },
    isServiceActive: (state: ServicesState) => (serviceName: ServiceDescription['name']) => {
      return state.services[serviceName]?.isActive
    },
  },

  mutations: {
    setService(state: ServicesState, service: ServiceDescription) {
      Vue.set(state.services, service.name, { ...service })
    },

    setServicePermissions(
      state: ServicesState,
      payload: { serviceName: string; permissions: ServicePermissionDescription[] }
    ) {
      Vue.set(state.services, payload.serviceName, {
        ...state.services[payload.serviceName],
        permissions: payload.permissions,
      })
    },

    setServiceInitializationStatus(
      state: ServicesState,
      payload: {
        serviceName: ServiceDescription['name']
        initializationStatus: ServiceDescription['initializationStatus']
        initializationDetails: ServiceDescription['initializationDetails']
      }
    ) {
      Vue.set(state.services, payload.serviceName, {
        ...state.services[payload.serviceName],
        initializationStatus: payload.initializationStatus,
        initializationDetails: payload.initializationDetails,
      })
    },

    setIsServiceActive(
      state: ServicesState,
      payload: { serviceName: ServiceDescription['name']; isActive: ServiceDescription['isActive'] }
    ) {
      Vue.set(state.services[payload.serviceName], 'isActive', payload.isActive)
    },

    resetState(state: ServicesState) {
      Object.assign(state, getDefaultState())
    },
  },

  actions: {
    async setService({ commit }: { commit: Commit }, service: ServiceDescription): Promise<void> {
      commit('setService', service)
    },

    async setServicePermissions(
      { commit }: { commit: Commit },
      payload: { serviceName: string; permissions: ServicePermissionDescription[] }
    ): Promise<void> {
      commit('setServicePermissions', payload)
    },

    async initializationFinishedCallback({ dispatch }: { dispatch: Dispatch; getters: any }) {
      dispatch('facadeApplications/getAllDynamicApplicationsNlsValues', null, { root: true })
      dispatch('initializeRequiredServices')
    },

    async initializeRequiredServices({ getters, dispatch }: { dispatch: Dispatch; getters: any }) {
      for (const service of getters.servicesArray) {
        const rootComponent = getServiceRootComponent(service)

        if (!rootComponent?.meta?.isDisabled && rootComponent?.meta?.isForceInitializationRequired) {
          if (rootComponent.components?.length) {
            for (const embededComponent of rootComponent.components) {
              await dispatch('setupServiceComponent', embededComponent)
            }
          }
          await dispatch('setupServiceComponent', rootComponent)
        }
      }
    },

    async setupServiceComponent(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      serviceComponent: ServiceComponent
    ): Promise<void> {
      try {
        const isServiceComponentAlreadyRegistered = !!customElements.get(serviceComponent.id)
        if (isServiceComponentAlreadyRegistered) {
          showConsoleError({ message: 'Component already registered', serviceName: serviceComponent.id })
          return
        }

        if (serviceComponent.type === 'web-component-embedded') {
          await dispatch('downloadServiceComponentScript', serviceComponent)
          return
        }

        // если компонент отключен вручную
        if (serviceComponent.meta?.isDisabled) {
          commit('setServiceInitializationStatus', {
            serviceName: serviceComponent.id,
            initializationStatus: SERVICE_INITIALIZATION_STATUSES.FAILED,
            initializationDetails: i18n.t('notifications.serviceHasBeenDisabledByAdministrator'),
          })
          return
        }

        commit('setServiceInitializationStatus', {
          serviceName: serviceComponent.id,
          initializationStatus: SERVICE_INITIALIZATION_STATUSES.IN_PROGRESS,
        })

        await dispatch('downloadServiceComponentScript', serviceComponent)
      } catch (error) {
        showConsoleError({ message: 'Failed to setup component', error, serviceName: serviceComponent.id })
      }
    },

    async downloadServiceComponentScript(
      { dispatch }: { dispatch: Dispatch },
      serviceComponent: ServiceComponent
    ): Promise<void> {
      const isDynamicService = serviceComponent.meta?.isDynamicService
      const isDynamicServiceCustomBundle = serviceComponent.meta?.isDynamicServiceCustomBundle
      const isDynamicObjectsService = serviceComponent.meta?.isDynamicObjectsService

      const script = await dispatch('generateServiceComponentScriptElement', {
        serviceComponent,
        isDynamicService,
        isDynamicServiceCustomBundle,
        isDynamicObjectsService,
      })
      if (!script) return

      document.body.appendChild(script)

      await new Promise<void>((resolve, reject) => {
        const successHandler = () => {
          dispatch('downloadServiceComponentScriptSuccessHandler', serviceComponent)
          resolve()
        }

        script.onerror = (error: Error) => {
          dispatch('downloadServiceComponentScriptErrorHandler', { serviceComponent, error })
          reject(error)
        }

        if (isDynamicService || isDynamicObjectsService) {
          successHandler()
        } else {
          script.onload = successHandler
        }
      })
    },

    async generateServiceComponentScriptElement(
      { dispatch }: { dispatch: Dispatch },
      {
        serviceComponent,
        isDynamicService,
        isDynamicServiceCustomBundle,
        isDynamicObjectsService,
      }: {
        serviceComponent: ServiceComponent
        isDynamicService: boolean
        isDynamicServiceCustomBundle: boolean
        isDynamicObjectsService: boolean
      }
    ): Promise<HTMLScriptElement | void> {
      try {
        let src = serviceComponent.src

        const script = document.createElement('script')
        script.async = true
        script.dataset.serviceName = serviceComponent.name

        let { dynamicServiceScriptContent, objectsDynamicServiceScriptContent } = await dispatch(
          'getCachedScriptsFromDOM',
          { isDynamicService, isDynamicObjectsService }
        )

        if (isDynamicServiceCustomBundle) {
          const apiPrefix = ['127.0.0.1', 'localhost'].includes(window.location.hostname) ? '/proxy' : ''
          src = `${apiPrefix}/${SERVICE_NAMES.GATEWAY_API_SERVICE}/${SERVICE_NAMES.FACADE_SERVICE}${src}`
          // src = src.replace(process.env.VUE_APP_ADMIN_PATH, '/')
        }

        // если требуется загрузить универсальный бандл DYNAMIC_SERVICE и он еще не загружен
        if (isDynamicService && !isDynamicServiceCustomBundle && !dynamicServiceScriptContent) {
          const response = await api.services.getServiceScript(src)
          dynamicServiceScriptContent = response.data

          dispatch('cacheScriptInDOM', {
            scriptId: SERVICE_NAMES.DYNAMIC_SERVICE,
            scriptContent: dynamicServiceScriptContent,
          })
          // если требуется загрузить универсальный бандл OBJECTS_SERVICE и он еще не загружен
        } else if (isDynamicObjectsService && !objectsDynamicServiceScriptContent) {
          const response = await api.services.getServiceScript(src)
          objectsDynamicServiceScriptContent = response.data

          dispatch('cacheScriptInDOM', {
            scriptId: SERVICE_NAMES.OBJECTS_SERVICE,
            scriptContent: objectsDynamicServiceScriptContent,
          })
        }

        // если это динамический сервис с универсальным бандлом, то заменяем в скрипте шаблонное имя DYNAMIC_SERVICE на реальное
        if (isDynamicService && !isDynamicServiceCustomBundle && dynamicServiceScriptContent) {
          script.textContent = dynamicServiceScriptContent.replaceAll(
            SERVICE_NAMES.DYNAMIC_SERVICE,
            serviceComponent.name
          )
          script.type = 'text/javascript'
          // если это объектный сервис с универсальным бандлом, то заменяем в скрипте шаблонное имя OBJECTS_SERVICE на реальное
        } else if (isDynamicObjectsService && objectsDynamicServiceScriptContent) {
          script.textContent = objectsDynamicServiceScriptContent.replaceAll(
            SERVICE_NAMES.OBJECTS_SERVICE,
            serviceComponent.name
          )
          script.type = 'text/javascript'
        } else if (isDynamicService && isDynamicServiceCustomBundle) {
          // если это динамический сервис с кастомным бандлом, то загружаем этот бандл
          const response = await api.services.getServiceScript(src)
          script.textContent = response.data
          script.type = 'text/javascript'
        } else {
          script.src = src
        }

        return script
      } catch (error) {
        dispatch('downloadServiceComponentScriptErrorHandler', { serviceComponent, error })
        return
      }
    },

    downloadServiceComponentScriptSuccessHandler(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      serviceComponent: ServiceComponent
    ) {
      if (serviceComponent.resources?.length) {
        dispatch('loadCustomAppResources', serviceComponent.resources)
      }

      // кастомное приложение само не сообщает о том, что инициализация завершена, поэтому
      // success статус устанавливается вручную
      if (serviceComponent.type === 'custom-app-root') {
        commit('setServiceInitializationStatus', {
          serviceName: serviceComponent.id,
          initializationStatus: SERVICE_INITIALIZATION_STATUSES.SUCCESS,
        })
      }
    },

    downloadServiceComponentScriptErrorHandler(
      { commit }: { commit: Commit },
      payload: { serviceComponent: ServiceComponent; error: unknown }
    ) {
      const message = i18n.t('notifications.failedToFetchServiceScript')
      showConsoleError({ message, error: payload.error, serviceName: payload.serviceComponent.name })

      if (
        payload.serviceComponent?.type === 'web-component-root' ||
        payload.serviceComponent?.type === 'custom-app-root'
      ) {
        commit('setServiceInitializationStatus', {
          serviceName: payload.serviceComponent?.name,
          initializationStatus: SERVICE_INITIALIZATION_STATUSES.FAILED,
          initializationDetails: message,
        })
      }
    },

    registerRoute(ctx: ActionContext<ServicesState, any>, service: ServiceDescription) {
      const rootComponent = getServiceRootComponent(service)

      if (rootComponent?.path) {
        router.addRoute({
          // имя роута в ui-base равно имени сервиса
          name: service.name,
          path: `${rootComponent.path}`,
          component: ServiceWrapperView,
          // пропсы будут переданы внутрь ServiceWrapper и далее в соответствующий сервис
          props: {
            service,
          },
        })

        router.addRoute({
          name: service.name,
          path: `${rootComponent.path}/*`,
          component: ServiceWrapperView,
          props: {
            service,
          },
        })
      }
    },

    cacheScriptInDOM(
      ctx: ActionContext<ServicesState, any>,
      { scriptId, scriptContent }: { scriptId: string; scriptContent: string }
    ) {
      const scriptElement = document.createElement('script')
      scriptElement.id = scriptId
      scriptElement.async = true
      scriptElement.type = 'text/javascript'
      scriptElement.textContent = scriptContent
      document.body.appendChild(scriptElement)
    },

    getCachedScriptsFromDOM(
      ctx: ActionContext<ServicesState, any>,
      { isDynamicService, isDynamicObjectsService }: { isDynamicService: boolean; isDynamicObjectsService: boolean }
    ) {
      let dynamicServiceScriptContent
      let objectsDynamicServiceScriptContent

      if (isDynamicService || isDynamicObjectsService) {
        const cachedDynamicScript = document.getElementById(SERVICE_NAMES.DYNAMIC_SERVICE)
        dynamicServiceScriptContent = cachedDynamicScript?.textContent
        const cachedObjectsScript = document.getElementById(SERVICE_NAMES.OBJECTS_SERVICE)
        objectsDynamicServiceScriptContent = cachedObjectsScript?.textContent
      }

      return { dynamicServiceScriptContent, objectsDynamicServiceScriptContent }
    },

    loadCustomAppResources(ctx: ActionContext<ServicesState, any>, resources: ServiceComponent['resources']) {
      resources?.forEach((resource) => {
        if (resource.type === 'js') {
          const script = document.createElement('script')
          script.async = true
          script.src = resource.href
          document.body.appendChild(script)
        } else if (resource.type === 'css') {
          const link = document.createElement('link')
          link.href = resource.href
          link.rel = 'stylesheet'
          document.head.appendChild(link)
        }
      })
    },

    async initializeCustomApp(ctx: ActionContext<ServicesState, any>, service: ServiceDescription) {
      try {
        setTimeout(() => {
          const rootComponent = getServiceRootComponent(service)!
          if (!Array.isArray(rootComponent)) {
            window[service.name].initialize(service.name, rootComponent.properties)
          } else {
            for (const component of rootComponent) {
              window[service.name].initialize(service.name, component.properties)
            }
          }
        })
      } catch (error) {
        showConsoleError({ message: 'Failed to initialize custom app', error, serviceName: service.name })
      }
    },

    async destroyCustomApp(ctx: ActionContext<ServicesState, any>, service: ServiceDescription) {
      try {
        window[service.name].destroy(service.name)
      } catch (error) {
        showConsoleError({ message: 'Failed to destroy custom app', error, serviceName: service.name })
      }
    },
  },
}
