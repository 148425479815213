














import Vue from 'vue'
import { getServiceRootComponent } from '@/common/utils'
import { SERVICE_INITIALIZATION_STATUSES } from '@docshouse/dh-ui-types'
import { ServiceDescription } from '@/types/services.types'
import { mapGetters } from 'vuex'
import ServiceWrapperView from '@/views/ServiceWrapperView.vue'
import _cloneDeep from 'lodash.clonedeep'

export default Vue.extend({
  name: 'SearchFieldWrapper',
  components: { ServiceWrapperView },

  props: {
    isSearchFieldVisibleModel: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      components: [] as any[],
    }
  },

  computed: {
    ...mapGetters({
      serviceInitializationStatus: 'services/serviceInitializationStatus',
      servicesInitializedArray: 'services/servicesInitializedArray',
    }),
    isSearchServiceInitializationInProgress(): boolean {
      return this.serviceInitializationStatus('dh-queries-service') === SERVICE_INITIALIZATION_STATUSES.IN_PROGRESS
    },
    isSearchServiceInitialized(): boolean {
      return this.serviceInitializationStatus('dh-queries-service') === SERVICE_INITIALIZATION_STATUSES.SUCCESS
    },
    searchServiceConponent(): any {
      return this.components.find((component: any) => component.id === 'ldm-search-service-ui')
    },
    searchServiceConponentSetting(): any {
      const component = this.searchServiceConponent
      if (component?.properties) {
        component.properties = {
          ...component?.properties,
          isBackgroundMode: true,
        }
      }

      return component
    },
    isSearchFieldVisible: {
      get: function (): boolean {
        return this.isSearchFieldVisibleModel
      },
      set: function (value: boolean) {
        this.$emit('update:isSearchFieldVisibleModel', value)
      },
    },
  },

  watch: {
    isSearchServiceInitialized: {
      immediate: true,
      handler: function (isInitialized: boolean) {
        if (isInitialized) {
          this.setSearchServiceInBackground()
        }
      },
    },
  },

  methods: {
    setSearchServiceInBackground() {
      const searchQueriesService: ServiceDescription = this.servicesInitializedArray.find(
        (service: ServiceDescription) => service.name === 'dh-queries-service'
      )
      const searchQueriesServiceInBackground = _cloneDeep(searchQueriesService)
      const rootQueriesComponent = getServiceRootComponent(searchQueriesServiceInBackground)!
      this.components = rootQueriesComponent?.components ?? []
    },
    selectSearchItem({ detail }: CustomEvent) {
      const payload = detail[0]
      this.$navigate(
        { path: `/${payload.topLevelRoute}/${payload.item}`, query: { updateRootTab: 'true' } },
        payload.isNavigateToSameService
      )
    },
    runSearch({ detail }: CustomEvent) {
      const payload = detail[0]
      this.$navigate(
        {
          path: `/${payload.topLevelRoute}/facetSearch/new/${payload.tabId}?search=${payload.search}`,
          query: { updateRootTab: 'true' },
        },

        payload.checkRouter
      )
    },
  },
})
