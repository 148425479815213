import Vue from 'vue'
import api from '@/api/api'
import axiosInstance from '@/api/axios-instance'
import i18n from '@/plugins/i18n'
import { ActionContext, Commit, Dispatch } from 'vuex'
import { RequestPageParams, SERVICE_NAMES } from '@docshouse/dh-ui-types'
import { ApplicationDto, FrameDto, FrameOptionDto, NlsValueDto } from '@docshouse/dh-facade-utils-ui'
import { ServiceDescription } from '@/types/services.types'
import { getServiceRootComponent } from '@/common/utils'

interface ApplicationsState {
  nlsValues: Record<NlsValueDto['code'], NlsValueDto[]>
}

export default {
  namespaced: true,

  state: {
    nlsValues: {},
  } as ApplicationsState,

  getters: {
    translateByNlsCode:
      (state: ApplicationsState) =>
      (nlsCode: NlsValueDto['code'], locale = i18n.locale) => {
        const targetNlsValue = state.nlsValues[nlsCode]?.find((value) => value.locale === locale)
        return targetNlsValue?.value ?? nlsCode
      },
  },

  mutations: {
    setNlsValues(state: ApplicationsState, payload: { nlsCode: NlsValueDto['id']; nlsValues: NlsValueDto[] }) {
      Vue.set(state.nlsValues, payload.nlsCode, payload.nlsValues)
    },
  },

  actions: {
    async getApplicationNlsValuesByNlsCode(
      { commit }: { commit: Commit },
      {
        applicationId,
        nlsCode,
        params,
      }: {
        applicationId: ApplicationDto['id']
        nlsCode: NlsValueDto['code']
        params?: RequestPageParams<NlsValueDto>
      }
    ): Promise<NlsValueDto[]> {
      const response = await api.facade.applications.getApplicationNlsValuesByNlsCode(applicationId, nlsCode, {
        baseURL: `${axiosInstance.defaults.baseURL}/${SERVICE_NAMES.FACADE_SERVICE}`,
        params,
      })
      const nlsValues = response.data
      commit('setNlsValues', { nlsCode, nlsValues })

      return nlsValues
    },

    async getAllDynamicApplicationsNlsValues({ dispatch, rootGetters }: { dispatch: Dispatch; rootGetters: any }) {
      rootGetters['services/servicesArray'].forEach((service: ServiceDescription) => {
        const rootComponent = getServiceRootComponent(service)

        if (!rootComponent?.meta?.isDisabled && rootComponent?.meta?.isDynamicService) {
          const applicationId = rootComponent?.properties?.id
          const nlsCode = service.name
          dispatch('facadeApplications/getApplicationNlsValuesByNlsCode', { applicationId, nlsCode }, { root: true })
        }
      })
    },

    async getFrameOptionsByFrameId(
      ctx: ActionContext<ApplicationsState, any>,
      payload: { frameId: FrameDto['id']; params?: RequestPageParams<FrameOptionDto> }
    ): Promise<FrameOptionDto[]> {
      const response = await api.facade.frames.getFrameOptionsByFrameId(payload.frameId, { params: payload.params })
      return response.data
    },
  },
}
