import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import '@/plugins/dh-ui-components'
import { navigate } from '@/common/utils'
import App from '@/App.vue'
import { SERVICE_NAMES } from '@docshouse/dh-ui-types'
import { FrameOptionDto, IFRAME_PROHIBITED_PARAMS } from '@docshouse/dh-facade-utils-ui'

Vue.config.productionTip = false
Vue.prototype.$baseAppName = SERVICE_NAMES.BASE
Vue.prototype.$navigate = navigate
Vue.prototype.$isDebug = false

/**
 * Функция для обработки состояния приложения, если оно запущено внутри iframe.
 * Выполняет следующие шаги:
 * 1. Определяет, что приложение запущено внутри iframe
 * 2. Извлекает параметр frameId из URL
 * 3. Получает опции по frameId с помощью
 * 4. Удаляет параметр frameId из URL и сохраняет разрешенные параметры в хранилище
 */
async function resolveIframeState() {
  // проверяем, запущено ли приложение внутри iframe
  const isEmbeddedInIframe = window !== window.parent
  if (isEmbeddedInIframe) {
    store.commit('settings/setIsEmbeddedInIframe', true)

    // получаем текущий URL
    const url = new URL(location.href)

    // извлекаем frameId из URL
    const frameId = url.searchParams.get('frameId')
    if (!frameId) {
      console.error('frameId не найден в URL. Опции фрейма не будет переданы в динамические приложения')
      return
    }

    // по frameId получаем опции фрейма, созданные через facade-service
    let frameOptions: FrameOptionDto[] = []
    try {
      frameOptions = await store.dispatch('facadeApplications/getFrameOptionsByFrameId', { frameId })
    } catch (error) {
      console.error(
        'Не удалось получить опции фрейма по frameId. Опции фрейма не будет переданы в динамические приложения'
      )
      return
    }

    // разрешенные параметры, которые нужно сохранять в хранилище и удалить из URL
    const allowedParams = frameOptions.map((option) => option.name)
    // запрещенные параметры, которые не нужно сохранять в хранилище и удалять из URL
    const ignoredQueryParams = Object.values(IFRAME_PROHIBITED_PARAMS)
    const paramsToDeleteFromUrl: string[] = []

    const iframeOptions: Record<string, string> = {}
    try {
      // фильтруем и удаляем параметры из URL, которые разрешены и не входят в список запрещенных
      url.searchParams.forEach((value, key) => {
        if (!ignoredQueryParams.includes(key) && allowedParams.includes(key)) {
          iframeOptions[key] = value
          paramsToDeleteFromUrl.push(key)
        }
      })
      // явно удаляем параметр frameId из URL
      url.searchParams.delete('frameId')
      // сохраняем отфильтрованные параметры в хранилище
      store.commit('settings/setIframeOptions', iframeOptions)

      // удаляем лишние параметры из URL
      paramsToDeleteFromUrl.forEach((key) => url.searchParams.delete(key))

      // обновляем URL
      window.history.replaceState({}, '', url.toString())
    } catch (error) {
      console.error(
        'Не удалось обработать опции фрейма. Опции фрейма не будет переданы в динамические приложения',
        error
      )
    }
  }
}

function resolveR7State() {
  const url = new URL(location.href)
  url.searchParams.forEach((value, key) => {
    if (key === 'isHideNavigatePanel') {
      store.commit('settings/setR7Options', true)
    }
  })
}

async function initializeApp() {
  await resolveIframeState()
  resolveR7State()

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
}

initializeApp()
