import _merge from 'lodash.merge'
import { commonMessages } from '@docshouse/dh-ui-components'
import { LocaleMessages } from 'vue-i18n'

export const messages: LocaleMessages = _merge(commonMessages, {
  ru: {
    logIn: 'Вход в систему',
    loginPlaceholder: 'Имя пользователя',
    passPlaceholder: 'Пароль',
    systemInitializationInProgress: 'Инициализация системы...',
    serviceInitializationInProgress: 'Инициализация сервиса...',
    welcomeToSystem: 'Добро пожаловать в {systemName}',
    pageDoesNotExist: 'Такой страницы не существует',
    userName: 'Имя пользователя',
    userRoles: 'Роли',
    baseApp: 'Базовое приложение',
    appName: 'Имя приложения',
    isRegistryEnabled: 'Поддержка Registry Service',
    isCustomAuthorization: 'Кастомная авторизация',
    isDynamicServicesEnabled: 'Динамические сервисы',
    isDarkThemeAvailable: 'Поддержка темной темы',
    isInternationalizationAvailable: 'Интернационализация',
    isSystemInfoAvailable: 'Информация о системе',
    isLoggerAvailable: 'Логирование',
    isRestoreDefaultsAvailable: 'Восстановление настроек по умолчанию',
    isSearchAvailable: 'Поиск',
    isNotificationsInBackgroundAvailable: 'Уведомления в фоновом режиме',
    isExpandDrawerOnHover: 'Открытие навигационной панели по наведению',
    notificationDisplayDuration: 'Длительность показа уведомлений (ms)',
    notificationConcurrentCount: 'Одновременное количество уведомлений',
    serviceName: 'Имя сервиса',
    serviceVersion: 'Версия сборки',
    serviceDescription: 'Описание сервиса',
    embeddedComponents: 'Встроенные компоненты',
    forceInitialize: 'Инициализировать',
    servicesSearch: 'Поиск по сервисам',
    nameRole: 'Наименование роли',
    search: 'Поиск',
    logEventType: 'Тип события',
    logEventSummary: 'Основная информация',
    logEventDetails: 'Подробности',
    logEventTimestamp: 'Дата и время',
    logEventSource: 'Источник',
    logEventsSearch: 'Поиск по событиям',
    facetSearch: 'Поиск',
    queryConstructor: 'Конструктор запросов',
    savedRequests: 'Сохраненные запросы',
    profile: 'Профиль',
    buttons: {
      yes: 'Да',
      cancel: 'Отмена',
      logIn: 'Войти',
      save: 'Сохранить',
      close: 'Закрыть',
      closeSearch: 'Закрыть',
      restore: 'Сбросить кэш',
      darkModeOn: 'темная',
      lightModeOn: 'светлая',
      switchToRu: 'Переключить на Русский',
      switchToEn: 'Переключить на Английский',
      pinDrawerOn: 'Закрепить боковое меню',
      pinDrawerOff: 'Открепить боковое меню',
      systemInfo: 'Информация о системе',
      accountInfo: 'Информация о пользователе',
      systemLog: 'Лог',
      restoreDefaults: 'Сбросить кэш',
      logout: 'Выйти',
      closeAllTabs: 'Закрыть все вкладки',
      theme: 'Тема:',
      locale: 'Язык:',
      toEn: 'английский',
      toRu: 'русский',
      systemRole: 'Мои роли',
      systemProfile: 'Профиль:',
      exitProfile: 'Выйти из профиля',
    },
    dialogs: {
      tabHasUnsavedChangesTitle: 'Закрыть вкладку?',
      tabHasUnsavedChangesContent: 'Внесенные изменения не сохранены и будут утеряны.',
      someTabsHasUnsavedChangesTitle: 'Закрыть все вкладки?',
      someTabsHasUnsavedChangesContent: 'Внесенные изменения на некоторых вкладках не сохранены и будут утеряны.',
      modalHasUnsavedChangesTitle: 'Закрыть окно?',
      modalHasUnsavedChangesContent: 'Внесенные изменения не сохранены и будут утеряны.',
      servicesInfoTitle: 'Информация о подключенных сервисах',
      systemLogTitle: 'Лог',
      systemRoleTitle: 'Мои роли',
      systemProfileTitle: 'Выбрать профиль',
      restoreDefaultsTitle: 'Сбросить кэш?',
      restoreDefaultsContent: 'Данные останутся в облачном хранилище и могут быть загружены снова в любой момент',
    },
    notifications: {
      pageDoesNotExist: 'Такой страницы не существует',
      serviceNotInitialized: 'Сервис не инициализирован',
      getInitialSystemSettingsError: 'Не удалось получить настройки системы. Обратитесь к администратору',
      getUserPermissionsError: 'Не удалось получить права доступа пользователя. Обратитесь к администратору',
      serviceHasBeenDisabledByAdministrator: 'Сервис был отключен администратором',
      failedToFetchServiceScript: 'Не удалось загрузить бандл',
      noPermissionsAvailable: 'Отсутствуют разрешения',
      serverError: 'Ошибка сервера',
      authError: 'Ошибка авторизации',
      unknownError: 'Неизвестная ошибка',
      featureInDevelopment: 'Функционал в стадии разработки',
      selectProfileSuccess: 'Профиль {name} успешно выбран.',
      exitProfileSuccess: 'Выход из профиля {name} произведен успешно',
    },
    navigationItems: {
      'dh-storages-service': 'Хранилища',
      'dh-files-service': 'Файлы',
      'dh-audit-service': 'Аудит',
      'dh-datamodel-service': 'Модели данных',
      'dh-schedulers-service': 'Планировщик',
      'dh-configurations-service': 'Конфигурации',
      'dh-accessrights-service': 'Права доступа',
      'dh-relations-service': 'Связи',
      'dh-notifications-service': 'Уведомления',
      'dh-authorization-edo-service': 'Шлюз ЭДО',
      'dh-dates-service': 'Контрольные даты',
      'dh-idp-service': 'IDP',
      'dh-documents-service': 'Документы',
      'dh-facade-service': 'Конструктор',
      'dh-dashboards-service': 'Дашборды',
      'dh-environments-service': 'Окружения',
      'dh-dynamic-service': 'Динамический сервис',
      'dh-subscriptions-service': 'Подписки',
      'dh-folders-service': 'Папки',
      'dh-collections-service': 'Коллекции',
      'dh-favorites-service': 'Избранное',
      'dh-queries-service': 'Сохраненные запросы',
      'dh-federal-search-service': 'Федеративный поиск',
      'dh-processes-service': 'Реестр процессов',
      'dh-tasks-service': 'Список задач',
      'ldm-organization-directory-service': 'Штатная структура',
      'ldm-shake-up-documents-service': 'Перетряхивание документов',
      'ldm-document-content-transfer-service': 'Перемещения контента',
      'ldm-uniqueness-service': 'Проверка уникальности',
      'ldm-user-settings-service': 'Настройки пользователя',
      'ldm-numerator-service': 'Шаблоны номеров',
      'ldm-reports-service': 'Сервис построения отчетов',
      'ldm-system-objects-service': 'Сервис системных моделей',
    },
  },
  en: {
    logIn: 'Please log in',
    logout: 'Logout',
    loginPlaceholder: 'Username',
    passPlaceholder: 'Password',
    buttons: {
      logIn: 'Log in',
      save: 'Save',
      darkModeOn: 'Dark mode on',
      lightModeOn: 'Light mode on',
      switchToRu: 'Switch to Russian',
      switchToEn: 'Switch to English',
      pinDrawerOn: 'Pin drawer',
      pinDrawerOff: 'Unpin drawer',
    },
    facetSearch: 'Facet search',
    queryConstructor: 'Query constructor',
    savedRequests: 'Saved requests',
    navigationItems: {
      'dh-storages-service': 'Storages Service',
      'dh-files-service': 'Files Service',
      'dh-audit-service': 'Audit Service',
      'dh-datamodel-service': 'Data Model Service',
      'dh-schedulers-service': 'Schedulers Service',
      'dh-configurations-service': 'Configurations Service',
      'dh-accessrights-service': 'Access Rights Service',
      'dh-relations-service': 'Relations Service',
      'dh-notifications-service': 'Notifications Service',
      'dh-authorization-edo-service': 'Authorization EDM Service',
      'dh-dates-service': 'Dates Service',
      'dh-idp-service': 'IDP Service',
      'dh-documents-service': 'Documents Service',
      'dh-facade-service': 'Facade Service',
      'dh-dashboards-service': 'Dashboards',
      'dh-environments-service': 'Environments Service',
      'dh-dynamic-service': 'Dynamic Service',
      'dh-subscriptions-service': 'Subscriptions Service',
      'dh-folders-service': 'Folders Service',
      'dh-collections-service': 'Collections Service',
      'dh-favorites-service': 'Favorites Service',
      'dh-queries-service': 'Queries Service',
      'dh-federal-search-service': 'Federal Search Service',
      'dh-processes-service': 'Processes Service',
      'dh-tasks-service': 'Tasks Service',
      'ldm-shake-up-documents-service-ui': 'Shake Up Documents Service',
      'ldm-user-settings-service': 'User settings',
    },
  },
})
