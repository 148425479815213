import Vue from 'vue'
import store from '@/store'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { RouteDescription } from '@docshouse/dh-ui-types'
import PageDoesNotExistView from '@/views/PageDoesNotExistView.vue'
import DashboardView from '@/views/DashboardView.vue'

Vue.use(VueRouter)

export const ROUTE_PATHS_STATIC = {
  BASE: process.env.VUE_APP_ADMIN_PATH,
  // AUTH: '/auth',
  PAGE_DOES_NOT_EXIST: '/404',
  PAGE_LOGOUT: '/logout',
}

const routes: RouteConfig[] = [
  {
    path: ROUTE_PATHS_STATIC.BASE,
    component: DashboardView,
  },
  {
    path: ROUTE_PATHS_STATIC.PAGE_DOES_NOT_EXIST,
    component: PageDoesNotExistView,
  },
  {
    path: ROUTE_PATHS_STATIC.PAGE_LOGOUT,
  },
  // остальные роуты верхнего уровня будут зарегистрированы динамически
]

const router = new VueRouter({
  mode: 'history',
  base: ROUTE_PATHS_STATIC.BASE,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (
    to.path.includes('/logout') &&
    localStorage.getItem('session_data') === null &&
    localStorage.getItem('registry_data') === null
  ) {
    if (process.env.NODE_ENV === 'development') {
      window.location.href = process.env.BASE_URL + 'proxy/dh-gateway-api-service/logout'
    } else {
      window.location.href = '/' + 'dh-gateway-api-service/logout'
    }
    return
  }
  if (!(await handleInitialSettings(next))) return

  // if (to.path === ROUTE_PATHS_STATIC.AUTH) {
  //   if (!isAuthenticated()) {
  //     await checkSessionData()

  //     return !isAuthenticated() ? next() : next({ path: ROUTE_PATHS_STATIC.BASE })
  //   }
  // }

  // if (!isAuthenticated()) {
  //   await checkSessionData()

  //   if (!isAuthenticated()) {
  //     setUnresolvedInitialNavigationPath(to.fullPath)
  //   }

  //   checkTokenExpiry()
  //   return next({ path: to.fullPath })
  // }

  // if (!isUserPermissionsReceived()) {
  //   await checkUserPermissions()

  //   setUnresolvedInitialNavigationPath(to.fullPath)

  //   await initializationFinishedCallback()
  // }
  if (!isUserPermissionsReceived()) {
    await checkUserPermissions()
    // if (!isUserPermissionsReceived()) {
    //   setUnresolvedInitialNavigationPath(to.fullPath)
    // }
    await initializationFinishedCallback()
    return next({ path: to.fullPath })
  }

  return next()
})

async function handleInitialSettings(next: NavigationGuardNext<Vue>): Promise<boolean> {
  if (!isInitialSettingsReceived() && !isFailedToReceiveInitialSystemSettings()) {
    try {
      await getUserInfo()
    } catch (error) {
      console.log(error)
    }
    await getInitialSystemSettings()

    if (!isInitialSettingsReceived()) {
      // next({ path: ROUTE_PATHS_STATIC.AUTH })
      return false
    }
  }
  return true
}

function isInitialSettingsReceived() {
  return store.getters['settings/isInitialSystemSettingsReceived']
}
function isFailedToReceiveInitialSystemSettings() {
  return store.getters['settings/isFailedToReceiveInitialSystemSettings']
}
async function getInitialSystemSettings() {
  await store.dispatch('settings/getInitialSystemSettings')
}
function isAuthenticated() {
  return store.getters['auth/isAuthenticated']
}
async function checkSessionData() {
  await store.dispatch('auth/checkSessionData')
}
async function checkTokenExpiry() {
  await store.dispatch('auth/checkTokenExpiry')
}
async function setUnresolvedInitialNavigationPath(path: RouteDescription['path']) {
  store.commit('ui/setUnresolvedInitialNavigationPath', path)
}
function isUserPermissionsReceived() {
  return store.getters['auth/isUserPermissionsReceived']
}
async function checkUserPermissions() {
  await store.dispatch('auth/checkUserPermissions')
}
async function getUserInfo() {
  await store.dispatch('usersInfo/getUserInfo')
}

async function initializationFinishedCallback() {
  await store.dispatch('services/initializationFinishedCallback')
}

export default router
